<script>
import BackArrow from "../../../components/BackArrow/index.vue";
export default {
  components: { BackArrow },
  props: ["id"],
  data() {
    return {
      loading: false,
      message: {
        snacshow: false,
        text: "",
        type: "",
        mode: "",
        timeout: 2000,
      },
      dados: {
        serie: "0",
        tipo_ambiente: "",
        modelo: "57",
        modalidade: "",
        tipo_emissao: "",
        versao_cte: "3.00",
      },
      valid: true,
      fieldRules: [(v) => !!v || "Este campo é requerido"],
      items: [
        { cod: "1", descricao: "PRODUÇÃO" },
        { cod: "2", descricao: "HOMOLOGAÇÃO" },
      ],
    };
  },
  created() {
    this.$store.dispatch("activeLoader", true);
    this.$http
      .get(`/configcte`)
      .then((r) => {
        this.dados = r.data[0];
        this.$store.dispatch("activeLoader", false);
      })
      .catch((e) => {
        this.$store.dispatch("activeLoader", false);
        this.$store.dispatch("showMessage", {
          show: true,
          color: "error darken-1",
          text: e,
        });
      });
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.salvar();
      } else {
        this.message.snacshow = true;
        this.$store.dispatch("showMessage", {
          show: true,
          color: "warning darken-1",
          text: "Atencão um ou mais campos precisam ser preenchidos!",
        });
      }
    },
    salvar() {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .post("/configcte/update", this.dados)
        .then((r) => {
          this.$store.dispatch("activeLoader", false);
          if (r.data.success) {
            this.$store.dispatch("showMessage", {
              show: true,
              color: "success darken-1",
              text: r.data.success,
            });
            setTimeout(() => {
              this.$router.push("/parametros/cte");
            }, 1500);
          }
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.$store.dispatch("showMessage", {
            show: true,
            color: "error darken-1",
            text: e,
          });
        });
    },
  },
};
</script>
